import React from 'react';
import { graphql } from 'gatsby';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ArticleCard from '../components/resources/ArticleCard';
import HrefLangManager from '../components/HrefLangManager';

const ResourceCategoryTemplate = ({ pageContext, data, location }) => {
	const {
		category,
		breadcrumb: { crumbs }
	} = pageContext;
	const { nodes } = data.allMdx;

	const dynamicMetaDescription = (category) => {
		var metaDescription;
		var titleDescription;

		if (category === 'hearing-loss') {
			titleDescription = 'In-Depth Hearing Loss Articles: Your Guide to Better Hearing | hear.com';
			metaDescription = 'Explore our comprehensive hearing loss category to learn about the various causes, symptoms, and effective treatment options available for managing hearing impairment.';
		} else if (category === 'hearing-aids') {
			titleDescription = 'Comprehensive Hearing Aids Articles: Your Guide to Better Hearing | hear.com';
			metaDescription = 'Discover invaluable insights, tips, and knowledge on various hearing aid topics to make informed decisions and enhance your hearing experience.';
		} else if (category === 'hearing-aid-success') {
			titleDescription = 'Expert Tips and Insights for better hearing | hear.com';
			metaDescription = 'Explore expert tips and insights to optimize your hearing experience and embark on the journey to improved auditory health.';
		} else if (category === 'hearing-aid-technology') {
			titleDescription = 'Cutting-Edge Hearing Aid Technology Articles: Enhance Your Listening Experience | hear.com';
			metaDescription = 'Explore our Hearing Aid Technology category for a deep dive into the latest advancements in hearing assistance.';
		} else if (category === 'hearing-aid-lifestyle') {
			titleDescription = 'Life and hearing aids: Lifestyle articles | hear.com';
			metaDescription = 'Learn how hearing aids can enhance your daily routines, social interactions, and overall well-being. Discover the art of living in harmony with better hearing.';
		} else {
			titleDescription = 'Hear.com Resource Articles - Road to better hearing';
			metaDescription = 'Uncover a wealth of knowledge in our resource articles, covering everything from hearing aids and hearing loss to practical tips for better hearing and the hearing aid lifestyle.';
		}

		return { titleDescription, metaDescription };
	};

	const { titleDescription, metaDescription } = dynamicMetaDescription(category);

	return (
		<Layout fontSerif={true}>
			<Seo title={titleDescription} description={metaDescription} />
			<HrefLangManager
				alternates={[
					['x-default', 'https://www.hear.com/resources/`nodes[0].frontmatter.category`'],
					['en', 'https://www.hear.com/resources/`nodes[0].frontmatter.category`'],
					['en-US', 'https://www.hear.com/resources/`nodes[0].frontmatter.category`'],
					['en-IN', 'https://www.hear.com/in/resources/`nodes[0].frontmatter.category`'], 
					['en-CA', 'https://ca.hear.com/resources/`nodes[0].frontmatter.category`']
				]}
			/>
			<div className="o-wrapper">
				<div className="o-row-2p5">
					<h1 className="c-resource-title" style={{ textTransform: 'capitalize' }}>
						{category.replace(/-/g, ' ')}
					</h1>
				</div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="o-row-4">
					<div className="c-resource-cards-wrapper">
						{nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={`/resources/${post.frontmatter.category}/${post.frontmatter.slug}`}
								title={post.frontmatter.articleCardTitle}
								description={post.frontmatter.articleCardDescription}
							/>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ResourceCategoryTemplate;

export const pageQuery = graphql`
	query ($category: String) {
		allMdx(limit: 1000, filter: { frontmatter: { category: { eq: $category } } }) {
			nodes {
				frontmatter {
					category
					slug
					articleCardTitle
					articleCardDescription
				}
			}
		}
	}
`;
